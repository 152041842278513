/* eslint-disable prettier/prettier */
import { FixedNumber } from '@ethersproject/bignumber'
import { walletStore } from '@/stores/wallet-store'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { blockchainHandler } from '@/blockchain'
import moment from 'moment'
import Web3 from 'web3'

//TODO: Change to mainnet
const web3 = blockchainHandler.getWeb3(137)!

const BSC_BLOCK_TIME = 2
const BLOCKS_PER_YEAR = FixedNumber.from(`${(60 / BSC_BLOCK_TIME) * 60 * 24 * 365}`) // 10512000

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', error => reject(error))
  })
}

export class StakingHandler {
  farmContract: any
  LPTokenContract: any
  lockDuration?: moment.Duration
  apyConfigs: { amount: FixedNumber; apy: FixedNumber }[] = []
  rewardToken?: string
  poolToken?: string

  constructor() {
    this.farmContract = new web3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_MAINNET_FARM_ADDRESS)
  }

  async load() {
    loadingController.increaseRequest()
    try {
      const rewardToken = await this.farmContract.methods.rewardToken().call()
      this.rewardToken = web3.utils.toChecksumAddress(rewardToken)
      const lockDuration = await this.farmContract.methods.lockDuration().call()
      // const {
      //   0: poolToken,
      //   1: allocPoint,
      //   2: lastRewardBlock,
      //   3: accRewardPerShare,
      //   4: lockDuration
      // } = await this.farmContract.methods.poolInfo(0).call()
      this.poolToken = web3.utils.toChecksumAddress(rewardToken)
      this.lockDuration = moment.duration(lockDuration, 'seconds')
      this.LPTokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
      this.apyConfigs = await this.getApyConfigs()
    } catch (error) {
      console.error(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.farmContract = new web3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_MAINNET_FARM_ADDRESS)
      this.LPTokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
    }
  }

  async approved(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_MAINNET_FARM_ADDRESS)
        .call()
      return !!+web3.utils.fromWei(allowance)
    } catch (error) {
      console.error(error)
      return false
    }
  }
  async approve(account) {
    const f = this.LPTokenContract.methods.approve(
      process.env.VUE_APP_MAINNET_FARM_ADDRESS,
      web3.utils.toWei(`${2 ** 64 - 1}`)
    )
    await sendRequest(f, account)
  }

  async getFarmLiquidityAndApy() {
    const amount = await this.farmContract.methods.totalAmount().call()
    const lockedAmount = FixedNumber.from(`${web3.utils.fromWei(amount)}`)
    return { lockedAmount }
  }
  async stakeLP(account, amount) {
    const f = this.farmContract.methods.deposit(web3.utils.toWei(`${amount.toString()}`))
    await sendRequest(f, account)
  }
  async unstakeLP(account) {
    const f = this.farmContract.methods.withdraw()
    await sendRequest(f, account)
  }
  async harvest(account) {
    const f = this.farmContract.methods.harvest()
    await sendRequest(f, account)
  }
  async getApyConfigs() {
    const { 0: amounts, 1: apys } = await this.farmContract.methods.getApyConfigs().call()
    return amounts.map((amount, index) => ({
      amount: FixedNumber.from(web3.utils.fromWei(amount)),
      apy: FixedNumber.from(web3.utils.fromWei(apys[index]))
    }))
  }
  async getUserInfo(account) {
    const { 0: amount, 2: lastStakeTime } = await this.farmContract.methods.userInfo(account).call()
    const apy = await this.farmContract.methods.getUserApy(account).call()
    return {
      amount: FixedNumber.from(`${web3.utils.fromWei(amount)}`),
      apy: FixedNumber.from(`${web3.utils.fromWei(apy)}`),
      lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null
    }
  }
  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call()
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`)
  }
  async getRewardAmount(account) {
    const amount = await this.farmContract.methods.getUserPendingReward(account).call()
    return FixedNumber.from(`${web3.utils.fromWei(amount)}`)
  }

  async getLockedAmount() {
    const amount = await this.farmContract.methods.totalAmount().call()
    const lockedAmount = FixedNumber.from(`${web3.utils.fromWei(amount)}`)
    return { lockedAmount }
  }
}
