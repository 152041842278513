import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { StakingHandler } from '@/helpers/staking-handler'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { action, computed, IReactionDisposer, observable, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Zero } from '@/constants'
import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { last, findLast, findLastIndex } from 'lodash'

export class StakingViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable stakeDialogInput = '0'
  @observable isDialogLoading = false
  @observable isFirstLoad = false

  @observable stakeDialog = false
  @observable unstakeDialog = false
  @observable harvestDialog = false

  @observable annualPercentageRate = FixedNumber.from('0')
  @observable rewardAmount = FixedNumber.from('0')
  @observable lastHarvestAmount = FixedNumber.from('0')

  @observable approved = false
  @observable approving = false
  @observable harvesting = false
  @observable apyConfigs: { amount: FixedNumber; apy: FixedNumber }[] = [
    {
      amount: FixedNumber.from('500'),
      apy: FixedNumber.from('5')
    },
    {
      amount: FixedNumber.from('2500'),
      apy: FixedNumber.from('10')
    },
    {
      amount: FixedNumber.from('5000'),
      apy: FixedNumber.from('15')
    },
    {
      amount: FixedNumber.from('10000'),
      apy: FixedNumber.from('20')
    }
  ]

  @observable lockDuration = moment.duration(0, 'second') // in seconds

  @observable stakedLP = FixedNumber.from('0')
  @observable userLPBalance = FixedNumber.from('0')
  @observable lastStakeTime: moment.Moment | null = null
  @observable totalLockedAmount = FixedNumber.from('0')

  stakingHandler?: StakingHandler

  constructor() {
    this.loadData()
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach(d => d())
  }

  async loadData() {
    const stakingHandler = new StakingHandler()
    this.stakingHandler = stakingHandler
    try {
      await stakingHandler.load()
      this.annualPercentageRate = last(this.stakingHandler.apyConfigs)?.apy || FixedNumber.from('20')
      this.apyConfigs = this.stakingHandler.apyConfigs
    } catch (error) {
      console.error('loadData', error)
    }
    this.lockDuration = stakingHandler.lockDuration!
    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const { lockedAmount } = await stakingHandler.getFarmLiquidityAndApy()

        runInAction(() => {
          this.totalLockedAmount = lockedAmount
        })
      })

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          //TODO: Change to mainnet
          if (walletStore.chainId == Number(137).toString()) {
            stakingHandler.injectMetamask(walletStore.web3!)
            stakingHandler.approved(walletStore.account).then(approved => runInAction(() => (this.approved = approved)))
            timer(0, 5000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo()
              })
          }
        }
      )
    )
  }

  async fetchPoolInfo() {
    if (!walletStore.account || !this.stakingHandler) {
      this.stakedLP = FixedNumber.from('0')
    } else {
      const { lockedAmount } = await this.stakingHandler.getLockedAmount()
      this.totalLockedAmount = lockedAmount
      const [{ amount, lastStakeTime, apy }, userLPBalance, rewardAmount] = await Promise.all([
        this.stakingHandler.getUserInfo(walletStore.account),
        this.stakingHandler.getUserLPBalance(walletStore.account),
        this.stakingHandler.getRewardAmount(walletStore.account),
        this.stakingHandler.getApyConfigs()
      ])
      this.stakedLP = amount
      this.lastStakeTime = lastStakeTime
      this.userLPBalance = userLPBalance
      this.rewardAmount = rewardAmount
      this.annualPercentageRate = apy.isZero() ? last(this.stakingHandler.apyConfigs)!.apy : apy
      if (!this.isFirstLoad) this.isFirstLoad = true
    }
  }

  @asyncAction *approve() {
    this.approving = true
    try {
      yield this.stakingHandler!.approve(walletStore.account)
      this.approved = true
    } catch (error) {
      snackController.error(error.message)
    }
    this.approving = false
  }

  @action.bound changeStakeDialogInput(input) {
    this.stakeDialogInput = input
  }

  @action.bound requestStakeLP() {
    this.stakeDialog = true
  }

  @action.bound requestUnstakeLP() {
    this.unstakeDialog = true
  }

  @action.bound maximum() {
    this.stakeDialogInput = this.maxDialogStakeBalance.toString()
  }

  @asyncAction *confirmStake() {
    this.isDialogLoading = true
    try {
      yield this.stakingHandler!.stakeLP(walletStore.account, this.stakeDialogInput)
      snackController.success('Stake UM successed')
      this.fetchPoolInfo()
      this.stakeDialogInput = '0'
      this.stakeDialog = false
    } catch (err) {
      snackController.error(err.message)
    } finally {
      this.isDialogLoading = false
    }
  }

  @asyncAction *confirmUnstake() {
    this.isDialogLoading = true
    try {
      yield this.stakingHandler!.unstakeLP(walletStore.account)
      snackController.success('Unstake UM successed')
      this.fetchPoolInfo()
      this.stakeDialogInput = '0'
      this.stakeDialog = false
    } catch (err) {
      snackController.error(err.message)
    } finally {
      this.isDialogLoading = false
    }
  }

  @asyncAction *restakeReward() {
    this.isDialogLoading = true
    try {
      yield this.stakingHandler!.stakeLP(walletStore.account, this.lastHarvestAmount)
      snackController.success('Stake UM successed')
      this.fetchPoolInfo()
      this.stakeDialogInput = '0'
      this.closeHarvestDialog()
    } catch (err) {
      snackController.error(err.message)
    } finally {
      this.isDialogLoading = false
    }
  }
  @asyncAction *harvest() {
    this.harvesting = true
    this.lastHarvestAmount = this.rewardAmount
    try {
      yield this.stakingHandler!.harvest(walletStore.account)
      this.fetchPoolInfo()
      snackController.success('Harvest successed')
      this.harvestDialog = true
    } catch (error) {
      snackController.error(error.message || error.msg)
    }
    this.harvesting = false
  }
  @action.bound cancelStakeDialog() {
    this.stakeDialogInput = '0'
    this.stakeDialog = false
  }
  @action.bound cancelUnstakeDialog() {
    this.unstakeDialog = false
  }
  @action.bound closeHarvestDialog() {
    this.lastHarvestAmount = FixedNumber.from('0')
    this.harvestDialog = false
  }
  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from('0'))
  }

  @computed get maxDialogStakeBalance() {
    return this.userLPBalance
  }
  @computed get lockInDays() {
    return this.lockDuration.asDays()
  }
  @computed get lockInSeconds() {
    return this.lockDuration.asSeconds()
  }
  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.lockDuration)
    }
    return null
  }
  @computed get canUnstake() {
    return moment().isAfter(this.canUnstakeTime)
  }
  @computed get canHarvest() {
    return bigNumberHelper.gt(this.rewardAmount, Zero)
  }
  @computed get validDialogInputAmount() {
    try {
      const amount = FixedNumber.from(this.stakeDialogInput)
      if (this.canUnstakeTime && this.canUnstakeTime.isAfter(moment())) return false
      if (amount.isNegative() || amount.isZero()) return false
      return bigNumberHelper.lte(amount, this.userLPBalance)
    } catch (error) {
      return false
    }
  }

  @computed get calculatedTokenStake() {
    return this.stakedLP.addUnsafe(
      !this.stakeDialogInput || this.stakeDialogInput === '0'
        ? FixedNumber.from('0')
        : FixedNumber.from(this.stakeDialogInput)
    )
  }

  @computed get calculatedApy() {
    const totalStake = this.calculatedTokenStake
    const calculatedTier = findLast(this.apyConfigs, config => bigNumberHelper.gte(totalStake, config.amount))
    if (!calculatedTier) return FixedNumber.from('0')
    return calculatedTier.apy
  }

  @computed get calculatedTier() {
    const totalStake = this.calculatedTokenStake
    const currentTierIndex = findLastIndex(this.apyConfigs, config => bigNumberHelper.gte(totalStake, config.amount))
    switch (currentTierIndex) {
      case 0:
        return 'Bronze'
      case 1:
        return 'Silver'
      case 2:
        return 'Gold'
      case 3:
        return 'Platinum'
      default:
        return 'None'
    }
  }

  @computed get earnAmountWithInput() {
    if (!this.stakeDialogInput || this.stakeDialogInput === '0') return '0'
    const apy = this.calculatedApy
    const totalStake = this.calculatedTokenStake
    return apy
      .mulUnsafe(totalStake)
      .divUnsafe(FixedNumber.from('12'))
      .divUnsafe(FixedNumber.from('100'))
  }
}
