










































































































































































import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { blockchainHandler } from '@/blockchain/index'

@Observer
@Component({
  components: {
    TierCarousel: () => import('../components/tier-carousel.vue'),
    StakingDialog: () => import('../dialogs/staking-dialog.vue'),
    UnstakeDialog: () => import('../dialogs/unstake-dialog.vue'),
    HarvestDialog: () => import('../dialogs/harvest-dialog.vue'),
  },
})
export default class Staking extends Vue {
  @Provide() vm = new StakingViewModel()
  walletStore = walletStore
  destroyed() {
    this.vm.destroy()
  }
  goToLink(url) {
    window.open(url)
  }
  viewContract() {
    //TODO: change to mainnet
    this.goToLink(
      `${blockchainHandler.getChainConfig(137).explorer}address/${process.env.VUE_APP_MAINNET_FARM_ADDRESS}`
    )
  }
}
